<template>
  <meta
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
    name="viewport"
  />
  <nav>
    <img
      src="https://epilogue.tekme.eu/favicon.ico"
      alt="epilogue_logo"
      style="width: 32px; margin-right: 10px;"
    />
    <a class="navbar-brand">Epilogue Moulitriche</a>
  </nav>
  <router-view />
</template>

<style>
@font-face {
  font-family: "JetBrains Mono NL";
  src: url("./fonts/jetbrains-mono/JetBrainsMonoNL-Light.ttf");
}

@font-face {
  font-family: "JetBrains Mono NL";
  src: url("./fonts/jetbrains-mono/JetBrainsMonoNL-Bold.ttf");
  font-weight: bold;
}

:root {
  --primary-color: #e2eafc;
  --primary-color-light: #f4f6f9;
  --primary-color-dark: #d7e3fc;
  --secondary-color: #b6ccfe;
  --secondary-color-light: #3b69bd;
  --secondary-color-dark: #3b69bd;

  --background-color: #ffffff;
  --on-primary-color: #303030;
  --on-secondary-color: #000000;
  --on-background-color: #303030;
  --shadow-color: #777777;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#app {
  height: 100%;
  width: 100%;
  margin: 0;
}

nav {
  background-color: #3b69bd;
  min-height: 60px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  padding: 0.26rem 1rem;
  color: white;
}

.navbar-band {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 28.25rem;
  font-weight: 400;
  line-height: inherit;
  white-space: nowrap;
}
</style>
